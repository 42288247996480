import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import * as conf from '../../config/config'
import $ from 'jquery';
import ImageGallery from './../../common/ImageGallery'
import CreateRevision from './../../containers/Admin/CreateRevision'
import {  Alert,  Button,  FormGroup,  Label,  Input,  Collapse,  Navbar,  NavbarToggler,
          NavbarBrand,  Nav,  NavItem,  NavLink,  Col,  Row,  Dropdown, DropdownToggle,
          DropdownMenu, DropdownItem } from 'reactstrap';

import Editor from './../../containers/Admin/Editor'
import Preview from './../../containers/Preview'
import RevisionList from '../../containers/Admin/RevisionList'
import MergeRevision from '../../common/Admin/MergeRevision'

const required = (value) => {
  if (!value.toString().trim().length) {
    return (<span className="error required">入力必須項目です</span>)
  }
};

class Create extends Component {
  
  constructor(props) {
    super(props);

    let status = ''
    if(typeof this.props.article.article === 'object'){
      if( this.props.article.article.status ){
        status = this.props.article.article.status
      }else{
        status = "draft"
      }
    }

    const { params }  = this.props.match,
          article_id  = parseInt( params.id, 10 )
    
    this.state = {
      isOpen: false,
      value: '',
      title: '',
      content: '',
      status: (status!='')?status:"draft",
      visible: false,
      error: false,
      message: '',
      articles: [],
      parent_id: '',
      slug: '',
      slug_check: true,
      dropdownOpen: false,
      setDropdownOpen: false,
      source_id: '',
      revision_title: '',
      revisions: [],
      visible_source: false,
      article_id: article_id
    }
    this.toggle        = this.toggle.bind(this)
    this.toggleMenu    = this.toggleMenu.bind(this)
    this._edit         = this._edit.bind(this)
    this.handleChange  = this.handleChange.bind(this)
    this.handleSubmit  = this.handleSubmit.bind(this)
    this.handleChangeSlug = this.handleChangeSlug.bind(this)    
    this.back           = this.back.bind(this)
    this.backParent     = this.backParent.bind(this)
    this.onDismiss      = this.onDismiss.bind(this)
    this.onDismissError = this.onDismissError.bind(this)
    
  }
  
  onDismiss(){
    this.setState({ visible: false})
  }
  onDismissError(){
    this.setState({ error: false})
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  _edit(text){
    this.setState({ value: text })
  }

  setArticle(result) {
    let status = '',
        color = ''
    switch(result.status){
    case "draft" :
      status = 'draft'
      color = 'secondary'
      break
    case "review":
      status = 'review'
      color = 'warning'
      break
    case "display":
      status = 'draft'
      color = 'success'
      break
    case "revision":
      status = "revision"
      color  = "info"
    default:
      break
    }
    const article = {
      title: result.title,
      content: result.content,
      status: status,
      color: color,
      article_id: result.id,
      update: result.updated_at,
      date: new Date(),
      condition: false,
      revision_title: result.revision_title,
      source_id: result.source_id
    }
    return article
  }

  toggleMenu(){
    this.setState({
      dropdownOpen : !this.state.dropdownOpen
    })
  }

  componentDidMount(){
    $('.preview_area, #editor, .editor_outer').css({
      'height': $(window).height() - $('.navbar').height() - $('.parent_select').height() + 'px'
    });
    this.loadEdit()
    this.getArticles()
    this.getRevisions()
  }

  getArticles(){
    axios.defaults.headers['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+"/v1/articles")
      .then((result)=>{
        let parent
        if(Array.isArray(result.data)){
          parent = result.data.map((data)=>{
            if(data.parent_id === 0){
              return data
            }
          })
        }
        this.setState({
          parents: parent,
          articles: result.data
        })
      })
      .catch((error)=>{
        console.log(error)
        this.setState({
          error:true
        })
      });
  }

  diffArticle(id){
    this.props.history.push('/admin/'+id+'/diff')
  }

  loadEdit(){

    const setArticle = this.setArticle
    if(!isNaN(this.state.article_id)){
      axios.defaults.headers['Authorization'] = localStorage.getItem('access_token')
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+this.state.article_id)
        .then((result)=>{
          this.props.handleSetArticle(setArticle(result.data))
          this.setState({
            id: result.data.id,
            title:result.data.title,
            value:(result.data.content)?result.data.content:'',
            status:result.data.status,
            parent_id: result.data.parent_id,
            slug: result.data.slug,
            source_id: result.data.source_id,
            revision_title: result.data.revision_title,
            visible_source: (result.data.source_id != null)?true:false
          })
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });
    }else{
      if( typeof this.props.article.article === 'object') {
        this.setState({
          parent_id: this.props.article.article.article_id
        })
      }
    }
  }

  getRevisions(){

    const {params} = this.props.match
    const id = parseInt(params.id, 10)
    if(!isNaN(id)){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+id+"/revisions")
        .then((result)=>{
          this.setState({
            revisions: result.data.articles
          })
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  }
  
  handleChange(event){
    const t = this,
          e = event
    this.setState({ [event.target.name]: event.target.value });
    if( event.target.name == 'slug' && event.target.value != ''){
      setTimeout(function(){
        //t.handleChangeSlug(e.target.value)
      },1000)
    }
  }

  handleChangeSlug(slug){

    let parent_id = ''
    const { params } = this.props.match
    const id = parseInt(params.id, 10)

    axios.defaults.headers['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+"/v1/admin/article_slug/"+slug)
      .then((result)=>{
        if(result.status === 200){
          if(result.data.id === id ){
            this.setState({
              slug_check: true
            })
          }else{
            this.setState({
              slug_check: false
            })
          }
        }else if(result.status === 205){
          this.setState({
            slug_check: true
          })
        }
      })
      .catch((error)=>{
        console.log(error)
      })
  }
  
  handleSubmit(e){
    e.preventDefault()
    
    let parent_id = ''
    const { params } = this.props.match
    const id = parseInt(params.id, 10)
    if(!isNaN(id)){
      parent_id = this.state.parent_id
    }else{
      if(typeof this.props.article.article == 'object'){
        parent_id = (this.props.article.article.article_id)?this.props.article.article.article_id:0
      }else{
        parent_id = 0
      }
    }
    const postData = {
      article: {
        title: this.state.title,
        content: this.state.value,
        status: (this.state.status)?this.state.status:"draft",
        parent_id:parent_id,
        revision_title: this.state.revision_title,
        slug: this.state.slug
      }
    }
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');

    if( isNaN(id) ){

      axios
        .post(conf.Conf.apiURL+"/v1/admin/articles",postData)
        .then((result)=>{
          this.setState({
            message:'success!',
            visible:true
          })
          this.props.history.push('/admin/'+result.data.id)
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });
    }else{

      axios
        .patch(conf.Conf.apiURL+"/v1/admin/articles/"+id,postData)
        .then((result)=>{
          this.setState({
            visible:true
          })
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });

    }
  }
  
  back = () => {    
    this.props.history.goBack()
  }
  backParent = () => {    
    this.props.history.push("/admin/"+ this.state.source_id)
  }

  categoryChild = (parent) => {
    if( typeof parent === 'object') {
      if(Array.isArray(this.state.articles)){
        return this.state.articles.map((data)=>{
          if( data.parent_id === parent.id ){
            return <option key={data.id} value={data.id}> - {data.title}</option>
          }
        })
      }
    }
  }

  render(){

    let parents = ''
    let child = this.categoryChild()

    if(Array.isArray(this.state.articles)){
      parents = this.state.articles.map((data)=>{
        if( data.parent_id == 0 ){
          return (
            <optgroup label={data.title} key={data.id}>
              <option key={data.id} value={data.id}>{data.id} {data.title}</option>
              {this.categoryChild(data)}
            </optgroup>
          )
        }
      })
    }
    
    return(
      <div className="create">
        <Alert isOpen={this.state.visible_source} className="w-100 d-block mb-0 text-center" color="warning">この記事は{this.state.source_id}のリビジョンデータです</Alert>
        <Navbar color="light" light expand="md" className="d-flex">
          <NavbarBrand className="title_form">
            
            <FormGroup row>
              <Col sm={7}>
                <Input className="form-control" type="text" name="title" value={this.state.title}  placeholder="タイトル" onChange={this.handleChange}   required />
              </Col>
              <Col sm={5}>
                {(()=>{
                  if( this.state.source_id != null && this.state.source_id != '' ){
                    return (
                      <Input className="form-control" type="text" name="revision_title" value={this.state.revision_title}  placeholder="リビジョン名" onChange={this.handleChange}   required />
                    )
                  }else{
                    if(this.state.revisions.length > 0){
                      return(<p className="fs-13 mb-0">新しいリビジョンがあります。</p>)
                    }
                  }
                })()}
              </Col>
            </FormGroup>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            
            <Row className="w-100">
              <Nav sm={7} className="d-flex ml-auto">
                <NavItem>
                  <NavLink>
                    <ImageGallery />
                  </NavLink>
                </NavItem>
                {(()=>{
                  if( this.state.source_id == null ){
                    return (
                      <NavItem>
                        <NavLink>
                          <CreateRevision match={this.props.match} history={this.props.history} loadEdit={()=>this.loadEdit()} loadRevision={()=>this.getRevisions()} />
                        </NavLink>
                      </NavItem>
                    )
                  }
                })()}
                <NavItem>
                  <NavLink>
                    <select type="select" value={this.state.status} name="status" id="exampleSelect" size="md" onChange={this.handleChange} value={this.state.status} className="form-control">
                      <option value="draft">下書き</option>
                      <option value="review">レビュー待ち</option>
                      <option value="display">公開</option>
                    </select>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Button color="success" size="sm" onClick={this.handleSubmit}>
                      保存
                    </Button>
                  </NavLink>
                </NavItem>
                {(()=>{
                  if( this.state.source_id != null ){
                    return(
                      <NavItem>
                        <NavLink>
                          <MergeRevision props={this.props} match={this.props.match} history={this.props.history} />
                        </NavLink>
                      </NavItem>
                    )
                  }
                })()}
                {(()=>{
                  if( this.state.source_id == null ){
                    return(
                      <NavItem>
                        <NavLink>
                          <RevisionList match={this.props.match} history={this.props.history} />
                        </NavLink>
                      </NavItem>
                    )
                  }
                })()}

                {(()=>{
                  if( this.state.source_id == null ){
                    return(
                      <NavItem>
                        <NavLink>
                          <Button color="secondary" size="sm" onClick={this.back}>
                            戻る
                          </Button>
                        </NavLink>
                      </NavItem>
                    )
                  }
                })()}
                {(()=>{
                  if( this.state.source_id != null ){
                    return(
                      <NavItem className="d-flex align-items-center">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleMenu}>
                          <DropdownToggle className="" caret size="sm" color="light">
                            <i className="fal fa-ellipsis-h fs-16 toggleMenu"></i>
                          </DropdownToggle>

                          
                          <DropdownMenu right>
                            
                            <DropdownItem className="fs-12" onClick={this.backParent}>親記事に戻る</DropdownItem>
                            <DropdownItem className="fs-12">リビジョンをマージする</DropdownItem>
                            
                            <DropdownItem className="fs-12" onClick={(()=>this.diffArticle(this.state.id))}>差分確認</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    )
                  }
                })()}

              </Nav>
            </Row>
          </Collapse>
        </Navbar>
        <Row >
          <Col className="parent_select" sm="6">
            <FormGroup row>
              <Label htmlFor="parent" sm={3}>親記事を選択</Label>
              <Col sm={9}>
                <select className="form-control" id="parent" value={this.state.parent_id} name="parent_id" size="md" onChange={this.handleChange}>
                  <option value="0">親なし</option>
                  {parents}
                </select>
              </Col>
            </FormGroup>
          </Col>
          <Col className="parent_select" sm="6">

            <Input className={"form-control "+
                              (()=>{
                                if(!this.state.slug_check){
                                  return 'is-invalid-input'
                                }
                                })()}
                   type="text" name="slug" value={this.state.slug} placeholder="URL" onChange={this.handleChange} required />
            {(()=>{
              if(!this.state.slug_check){
                return (<span className="error required">既に使用されています</span>)
              }
                
            })()}
          </Col>
        </Row>
        <Alert isOpen={this.state.visible} color="success" fade={true}  toggle={this.onDismiss}>success!</Alert>
        <Alert isOpen={this.state.error} color="danger" fade={true}  toggle={this.onDismissError}>something wrong!</Alert>
        <Row style={{"minHeight": "100%", "margin": 0}} className="editor_outer">
          <Col>
            <Editor
              edit={this._edit}
              value={this.state.value}
            />
          </Col>
          <Col className="preview_area">
            <Preview
              data={this.state.value} match={this.props.match} history={this.props.history}
            />
          </Col>
        </Row>
      </div>
    )
  }

}

export default withRouter(Create)
