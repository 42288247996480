import React, { Component } from 'react'
import axios from 'axios'
import * as conf from '../../config/config.js'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import {Animated} from "react-animated-css"

class Login extends Component {

    constructor(props){
        super(props)
        this.state = {
            value: '',
            email: '',
            password: '',
            showFlag: true,
            open: 'disabled'
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeButton  = this.closeButton.bind(this);
    }

    closeButton(){
        this.setState({
            showFlag: true,
            open: 'disabled'
        });
        return false;
    }

    
    handleChange(event){
        switch(event.target.name){
        case 'email':
            this.setState({
                email : event.target.value
            })
            break;
        case 'password':
            this.setState({
                password : event.target.value
            })
            break;
        default :
            break;
        }
        
    }

    handleSubmit(event) {
        let data = this.state;
        const postData = {
            email: this.state.email,
            password: data.password
        }
        axios
            .post(conf.Conf.apiURL+"/v1/login",postData)
            .then((result)=>{
                switch(result.status){
                case 200:
                    console.log(result)
                    axios.defaults.headers.common['access_token'] = result.data.access_token
                    localStorage.setItem('access_token',axios.defaults.headers.common['access_token'] );
                    window.location.href= '/admin';
                    break
                default:
                    break
                }
            })
            .catch((error)=>{
                this.setState({
                    showFlag: false,
                    open: 'active'
                });
            })
    }

    render(){
        return(
            <div className="container col-sm-12 col-md-6 offset-md-3  mt-5">
              <FormGroup className="mb-4 mr-sm-2 mb-sm-4">
                <Label htmlFor="login" className="mr-sm-2 fs-14">Email</Label>
                <Input type="email" id="login" className="fadeIn second form-control fs-14 py-4 px-4" name="email" placeholder="email" onChange={this.handleChange} />
              </FormGroup>
              <FormGroup className="mb-4 mr-sm-2 mb-sm-2">
                <Label htmlFor="password" className="mr-sm-2 fs-14">Password</Label>
                <Input type="password" id="password" className="fadeIn third form-control fs-14" name="password" placeholder="password" onChange={this.handleChange} />
              </FormGroup>

              <Button color="primary" className="mt-2 fadeIn fourth" value="Log In" onClick={this.handleSubmit}>login</Button>
              <Animated  animationIn="fadeIn" animationOut="fadeOut" className={'mt-2 alert'+' '+'alert-danger'+' '+this.state.open} role="alert" style={{ display: this.state.showFlag ? 'none' : '' }}>
                <button type="button" className="btn btn-md primary close" style={{ display: this.state.showFlag ? 'none' : '' }} onClick={this.closeButton}  >
                  <span aria-hidden="true">&times;</span>
                </button>
                <strong>Oh snap!</strong> Change a few things up and try submitting again.
              </Animated>
            </div>
            
        )
    }
}
export default Login
