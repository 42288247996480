import React, { Component } from 'react'
import * as conf from '../config/config'
import axios from 'axios'
import $ from 'jquery'
import { Col, Row, Input, FormGroup, Label, Button } from 'reactstrap'
import ReactDiffViewer from 'react-diff-viewer'

class Diff extends Component {

  constructor(props){
    super(props)

    const { params }  = this.props.match,
          revision_id = parseInt( params.revision_id, 10 ),
          article_id  = parseInt( params.id, 10 )

    this.state = {
      revision: {},
      anchorLink: '',
      revision_id: revision_id,
      article_id:  article_id
    }
    this.showRevision = this.showRevision.bind(this)
    this.setArticle   = this.setArticle.bind(this)
    this.getRevisions = this.getRevisions.bind(this)
    this.back         = this.back.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
    this.getArticle()
    this.getRevision()
    this.getRevisions()
    window.scrollTo(0,0)
  }

  handleChange(event){
    const t = this,
          e = event

    this.props.history.push("/components/"+this.state.article_id+"/revision/"+event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
    this.getRevision(event.target.value)
  }

  getArticle(){
    
    const t = this,
          setArticle = this.setArticle
    let res
    if( !isNaN(this.state.article_id)) {
      axios
        .get(conf.Conf.apiURL + "/v1/articles/" + this.state.article_id)
        .then((result) => {
          res = result
          this.props.handleSetArticle(setArticle(result.data))
        })
        .catch((error) => {
          console.log(error)
          t.setState({
            error: true
          })
        })
    }
  }

  back = () => {
    this.props.history.push("/components/"+ this.state.article_id)
  }
  
  setArticle(result) {
    let status = '',
        color = ''
    switch(result.status){
    case "draft" :
      status = '下書き'
      color = 'secondary'
      break
    case "review":
      status = 'レビュー待ち'
      color = 'warning'
      break
    case "display":
      status = '公開'
      color = 'success'
      break
    default:
      break
    }
    const article = {
      title: result.title,
      content: result.content,
      status: status,
      color: color,
      article_id: result.id,
      update: result.updated_at,
      date: new Date(),
      condition: false,
      anchorLink: true
    }
    return article
  }

  getRevision(id){

    let target 
    if( id != undefined){
      target = id
    }else{
      target = this.state.revision_id
    }
    if( !isNaN( target )) {
      axios
        .get(conf.Conf.apiURL + "/v1/articles/" + target)
        .then((result) => {
          this.setState({
            revision: result.data
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            error: true
          })
        })
    }
  }

  getRevisions(){
    axios
      .get(conf.Conf.apiURL+"/v1/articles/"+this.state.article_id+"/revision")
      .then((result)=>{
        this.props.handleSetRevision(result.data.articles)
      })
      .catch((error)=>{
        console.log(error)
      })
  }
  
  showRevision(id){
    
  }

  render(){

    let content = '',
        title,
        revision
    
    if(typeof this.props.article.article === 'object'){
      if( this.props.article.article.content == null ){
        content = '本文なし'
      }else{
        content = this.props.article.article.content
      }
      if( this.props.article.article.title == null ) {
        title = '本文なし'
      }else{
        title = this.props.article.article.title
      }
    }

    if( typeof this.props.article === 'object' ){
      if( Array.isArray(this.props.article.revision)){
        revision = this.props.article.revision.map((data)=>{
          const selected = ( data.id == this.state.revision_id )?"selected":''
          return(
            <option key={data.id} value={data.id}>{data.revision_title}</option>
          )
        })
      }
    }
    
    return(
      <div className="row">
        <div className={(()=>{
          return ('col-12')
        })()}>
          <Row>
            <Col sm={8} className="px-0">
              <h1>{title} {this.props.article.anchorLink}</h1>
            </Col>
            <Col sm={4} className="pt-4 px-0">
              <p className="mb-0">比較中のリビジョン</p>
              <FormGroup className="d-flex">
                <Input type="select" name="revision" id="" className="fs-14 ml-2" onChange={this.handleChange} defaultValue={this.state.revision_id}>
                  {revision}
                </Input>
                <Button className="ml-2" onClick={this.back}>戻る</Button>
              </FormGroup>
            </Col>
          </Row>
          <div className="preview diff">
            <ReactDiffViewer
              oldValue={this.state.revision.content}
              newValue={this.props.article.article.content}
              splitView={true}
              showDiffOnly={false}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Diff
