import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Badge, Button, Alert } from 'reactstrap'
import AdminHeader from '../../common/AdminHeader'
import Preview from './../../containers/Preview'
import axios from 'axios'
import * as conf from '../../config/config'
import Moment from 'react-moment'

class AdminIndex extends Component {

  constructor(props) {
    super(props)

    this.orderUp     = this.orderUp.bind(this)
    this.orderDown   = this.orderDown.bind(this)
    this.getArticles = this.getArticles.bind(this)
    this.goBack      = this.goBack.bind(this)
  }

  orderUp(){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    if( typeof this.props.article === 'object'){
      axios
        .get(conf.Conf.apiURL+'/v1/admin/articles/'+this.props.article.article.article_id+'/orderup')
        .then((result)=>{
          this.getArticles()
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  }

  orderDown(){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    if( typeof this.props.article === 'object'){
      axios
        .get(conf.Conf.apiURL+'/v1/admin/articles/'+this.props.article.article.article_id+'/orderdown/')
        .then((result)=>{
          console.log(result.data)
          this.getArticles()
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  }

  getArticles(){

    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+'/v1/admin/articles/tree')
      .then((result)=>{
        this.props.handleSetArticles(result.data)
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  goBack = (id) => {
    this.props.history.push("/admin/"+id)
    
  }

  render(){
    let content = '',
        title = '',
        id = 0,
        status,
        update,
        color,
        default_message = `
## 登録
左上の+から新規追加を行えます。

## 修正
各ページの右上に「edit」ボタンがあるので、そこから修正を行ってください。
保存をする際に「下書き」「レビュー待ち」「公開」のステータスがあるので、
適時使い分けを行ってください。

## 入力方法
入力方法はマークダウン記法が使用できます。

__Qiita Markdown記法一覧__<br>
<https://qiita.com/oreo/items/82183bfbaac69971917f>

__Qiita Markdown書き方メモ__<br>
<https://qiita.com/Minalinsky_1911/items/b684cfabe0f2fde0c67b>
`;
    if(typeof this.props.article.article == 'object'){
      if( this.props.article.article.content == null ){
        content = default_message
      }else{
        content = this.props.article.article.content
      }
      if( this.props.article.article.title == null ){
        title = 'title'
      }else{
        title = this.props.article.article.title
      }
      id = this.props.article.article.article_id
      update = this.props.article.article.update
      status = this.props.article.article.status
      color = this.props.article.article.color

    }
    return(
      <div>
        {(()=>{
          if( typeof this.props.article === "object" ){
            if( this.props.article.article.source_id != null){
              return(
                <Alert color="warning" className="text-center mb-0">{this.props.article.article.source_id}の「
                  <span onClick={this.goBack(this.props.article.article.source_id)}>{this.props.article.article.revision_title}」</span>リビジョンを開いてます。</Alert>
              )
            }
          }
        })()}
        <AdminHeader title={title} id={id} />
        <div className="admin_view" >              
          <div className="status_bar">
            <Badge color={color}>{status}</Badge>&nbsp;&nbsp;&nbsp;
            last upate:<Moment format="YYYY/MM/DD HH:MM">{update}</Moment>
            <div className="sort">
              <Button color="link" onClick={this.orderDown}>
                <i className="fas fa-sort-amount-down"></i>
              </Button>
              &nbsp;                    
              <Button color="link" onClick={this.orderUp}>
                <i className="fas fa-sort-amount-up"></i>
              </Button>
            </div>
          </div>
          <Preview data={content} article_id={id} match={this.props.match} history={this.props.history} />
        </div>
      </div>
    )
  }
}

export default withRouter(AdminIndex)
