import React, { Component } from 'react'
import Highlight from 'react-highlight'
import marked from "marked";
import 'highlight.js/styles/github.css';
import CategoryTree from '../containers/CategoryTree'

class Preview extends Component {

  constructor(props){
    super(props)

    this.state = {
      content: this.props.data,
      article_id: this.props.article_id
    }
    this.openShortCut = this.openShortCut.bind(this)
  }

  componentDidMount(){
    document.addEventListener('DOMContentLoaded', (event) => {
      document.querySelectorAll('pre').forEach((block) => {
        Highlight.highlightBlock(block);
      })
    })
  }

  openShortCut(value){
    if(value.search("{{children}}") > -1 ){
      value = value.replace("{{children}}", <CategoryTree article_id={this.props.article_id} render="<div>hey</div>" />)
    }
    return (value)
  }

  test(){
    return <CategoryTree article_id={this.props.article_id} />
  }
  
  render(){

    let content = ''
    if(typeof this.props.article.article == 'object'){
      if( this.props.article.article.content == null ){
        content = '本文なし'
      }else{
        content = this.props.data
      }
    }else{
      content = this.props.data
    }

    return(
      <div className={"preview " + this.props.part}>
        {/* {(()=>{
          if(this.props.data.length > 0 ){
            
            return (
              <div>
                <h3 className="fs-16">ページリンク</h3>
                <CategoryTree article_id={this.props.article_id} render="<div>hey</div>" match={this.props.match} history={this.props.history} />
              </div>
            )
          }
        })()} */}
        
        <Highlight innerHTML={true}>
          {marked(content)}
        </Highlight>
      </div>

    )
  }
}
export default Preview
