import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom'
import * as conf from '../config/config'
import axios from 'axios'

class ArticleDelete extends Component {

  constructor(props){
    super(props)
    this.state = {
      modal: false,
    }
    this.toggle = this.toggle.bind(this)
    this.deleteArticle = this.deleteArticle.bind(this)
  }

  componentDidMount(){
  }

  deleteArticle(){
    const data = {
      id : this.props.articleId
    }
    
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    axios
      .delete(conf.Conf.apiURL + '/v1/admin/articles/'+this.props.articleId,data)
      .then((result)=>{
        switch(result.status){
        case 200:
          this.props.history.push('/admin')
          window.location.reload(false)
          this.toggle()
          break
        default:
          this.toggle()
          break
        }
      })
      .catch((error)=>{
        console.log(error)
      })

  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    //this.props.getNews()
  }


  render(){
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
    return(
      <div>
        <Button color="danger" size="sm" onClick={this.toggle}>
          delete
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>確認</ModalHeader>
          <ModalBody>
            本当に記事を削除しますか？
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteArticle}>delete!</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default withRouter(ArticleDelete)
