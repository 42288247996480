import React, { Component}  from "react"
import AceEditor from 'react-ace'

import 'brace/mode/markdown'
import 'brace/theme/textmate'
import $ from 'jquery'

export default class Editor extends Component{

  constructor(props){
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = {
      height: $('.preview_area').height()?$('.preview_area').height()+"px":"800px"
    }
  }

  componentDidMount(){
  }

  onChange(text){
    this.props.edit(text)
  }

  render(){

    return(
      <AceEditor
        mode="markdown" 
        theme="textmate"
        width="100%"
        height={this.state.height}
        name="editor"
        tabSize={2}
        showPrintMargin={false}
        highlightActiveLine={false}
        editorProps={{$blockScrolling: true}}
        onChange={this.onChange}
        value={this.props.value}
      />
    )
  }
}

