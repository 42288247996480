import React, { Component } from 'react'
import { Alert, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import * as conf from '../config/config'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import ReactImageMagnify from 'react-image-magnify'
import $ from 'jquery'
import { Animated } from 'react-animated-css'

class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      image_src: '',
      images: '',
      image_id: '',
      acceptedfiles: [],
      rejectedfiles: [],
      file_title: [],
      prev: [],
      mode: false,
      del: false,
      offset: 0,
      limit: 20,
      tota: 0,
      visible: false,
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.sendFile = this.sendFile.bind(this)
    this.getImageFiles = this.getImageFiles.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.selectImage = this.selectImage.bind(this)
    this.handleDeleteImage = this.handleDeleteImage.bind(this)
    this.copyUrl = this.copyUrl.bind(this)
    this.toggle = this.toggle.bind(this)
    this.onDismiss      = this.onDismiss.bind(this)
  }

  componentDidMount(){
    
  }


  onDismiss(){
    this.setState({ visible: false})
  }
  onDismissError(){
    this.setState({ error: false})
  }

  handlePageClick = data => {
    let selected = data.selected;
    this.setState({ offset: selected + 1 }, () => {
      this.getImageFiles()
    });
  };

  copyUrl(e){
    e.target.select()
    document.execCommand("Copy")
    this.setState({
      isOpen: !this.state.isOpen,
      visible: true
    });
  }
  
  getImageFiles(){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    axios
      .get(conf.Conf.apiURL + '/v1/admin/pictures/get/'+this.state.offset+'/'+this.state.limit)
      .then((result)=>{
        switch(result.status){
        case 200:
          this.setState({
            images: result.data.pictures,
            pageCount: Math.ceil( result.data.total / this.state.limit ),
            total: result.data.total,
            del: false
          })
          break
        default:
          break
        }
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  selectImage(e){

    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+'/v1/admin/pictures/'+e.target.value)
      .then((result)=>{
        switch(result.status){
        case 200:
          let prev = []
          prev.push(
            <div className="image_preview" key='test'>

              {(()=>{
                if(result.data.photo_file_name.match('pdf')){
                  return (<i className="fas fa-file-pdf mb-2"></i>)
                }else{
                  return (
                    <ReactImageMagnify {...{
                      smallImage: {
                        alt: 'Wristwatch by Ted Baker London',
                        isFluidWidth: true,
                        src: conf.Conf.apiURL+"/uploads/images/"+result.data.id+"/sm."+result.data.photo_file_name,
                        
                      },
                      largeImage: {
                        src: conf.Conf.apiURL+"/uploads/images/"+result.data.id+"/original."+result.data.photo_file_name,
                        width: 1200,
                        height: 1800,
                        onLoad: function(e){
                          console.log(e.target.style.width)
                          $('.enlargedImage_outer').css({
                            'width': $('.image-container').width() + 'px',
                            'height': $('.image-container').height() + 'px'
                          })
                        }
                      },
                      enlargedImageContainerClassName: 'enlargedImage_outer',
                      enlargedImageClassName: 'enlargedImage',
                      imageClassName: 'img-thumbnail mb-2',
                      className: 'img-thumbnail mb-2 enlargedImage_root',
                      enlargedImagePosition: 'beside'
                    }} />
                  )
                }
              })()}
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    オリジナル
                  </span>
                </div>
                <input type="text" value={conf.Conf.apiURL+"/uploads/images/"+result.data.id+"/original."+result.data.photo_file_name} readOnly="readonly" className="form-control" id="original-image" onClick={this.copyUrl} />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    中サイズ
                  </span>
                </div>
                <input type="text" value={conf.Conf.apiURL+"/uploads/images/"+result.data.id+"/md."+result.data.photo_file_name} readOnly="readonly" className="form-control" id="medium-image" onClick={this.copyUrl} />
              </div>
            </div>
          )

          this.setState({
            image_id: result.data.id,
            prev: prev,
            mode: false,
            del: true
          })
          break
        default:
          break
        }
      })
      .catch((error)=>{
        console.log(error)
      });
  }
  
  handleChange(e){
    let file_title = {
      [e.target.name]:  e.target.value
    }
    this.setState({
      file_title: file_title
    })
  }

  sendFile(){

    this.state.acceptedfiles.map((file,i) =>{
      let data = new FormData()
      data.append('picture[photo]',file)
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
      axios
        .post(conf.Conf.apiURL + '/v1/admin/pictures', data)
        .then((result)=>{
          this.getImageFiles()
          this.setState({
            prev: []
          })
        })
        .catch((error)=>{
          console.log(error)
        })
      return false
    })

  }

  handleDeleteImage(){

    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    axios
      .delete(conf.Conf.apiURL+"/v1/admin/pictures/"+this.state.image_id)
      .then((result)=>{
        this.getImageFiles()
        this.setState({
          del: false,
          image_id: '',
          prev: []
        })
      })
      .catch((error)=>{
        console.log(error)
      })
  }
  
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    if(!this.state.modal){
      this.getImageFiles()
    }
  }

  onFocus = () => {
    this.select()
  }
   

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      acceptedfiles: acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
    let prev = this.state.prev
    acceptedFiles.map((f,i) =>

                      prev.push(
                        <div className="image_preview" key={f.name}>
                          <img key={f.name} src={f.preview} className="img-thumbnail mb-2" alt={f.name}  />
                          <input type="text" name={"file_title["+i+"]"} className="form-control mb-2" placeholder="画像タイトルを入力してください" onChange={this.handleChange} value={this.state.file_title["+i+"]} />
                        </div>
                      )
                     )
    this.setState({
      prev: prev,
      mode: true,
      del: false,
    })

  }

  render() {
    
    let images = []
    if(Array.isArray(this.state.images)){
      images = this.state.images.map((file)=>
                                     <li key={file.id} className="list-inline-item">
                                       <input id={'image_'+file.id} type="radio" name="image_id" className="image_id" value={file.id} onClick={this.selectImage} />
                                       <label htmlFor={'image_'+file.id}>
                                         {(()=>{
                                           if(file.photo_file_name.match('pdf')){
                                             return (<i className="fas fa-file-pdf"></i>)
                                           }else{
                                             return (
                                               <Animated animationIn={"fadeIn"}>
                                                 <img src={conf.Conf.apiURL+"/uploads/images/"+file.id+"/sm."+file.photo_file_name} alt="file.file_name" className="img-thumbnail" style={{width: "150px"}} />
                                               </Animated>
                                             )
                                           }
                                      })()}
                                       </label>
                                     </li>
                                    )
    }
    
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
    
    return(
      <div>
        <Button size="sm" color="link" className="list-group-item-action" onClick={this.toggle}>
          <i className="far fa-image fs-20 pt-1"></i>
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>画像アップローダー</ModalHeader>
          <ModalBody>
            <div className="input-group mb-3">
              <Dropzone onDrop={this.onDrop.bind(this)}>
                {({getRootProps, getInputProps, isDragActive}) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                    >
                      <input {...getInputProps()} />
                      {
                        isDragActive ?
                          <p>Drop files here...</p> :
                          <p>Try dropping some files here, or click to select files to upload.</p>
                      }
                    </div>
                  )
                }}
              </Dropzone>
            </div>
            <div className="row">

              <div className="col-md-4 border-left pl-3 mb-2">
                {this.state.prev}
                {(()=>{
                  if(this.state.mode){
                    return(
                      <Button size="sm" color="primary" onClick={this.sendFile} className="mt-2">
                        upload
                      </Button>
                    )
                  }
                })()}
                {(()=>{
                  if(this.state.del){
                    return (
                      <Button size="sm" color="danger" onClick={this.handleDeleteImage} className="mb-4" >削除</Button>
                    )
                  }
                })()}
                <Alert isOpen={this.state.visible} color="primary" fade={true}  toggle={this.onDismiss}>コピーしました!</Alert>
              </div>
              
              <div className="col-md-8 pl-0 image-container">
                { this.state.images.length === 0 && 'no images' }
                <ul className="list-inline">
                  {images}
                </ul>
                <nav  aria-label="Page navigation example" className="nav_pagination">
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    subContainerClassName={'pages pagination page-link'}
                    activeClassName={'active'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                  />
                </nav>
                
              </div>
              
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }

}

export default ImageGallery
