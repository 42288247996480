import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Alert } from 'reactstrap'
import Preview from '../containers/Preview'
import tocbot from 'tocbot'
import $ from 'jquery'

class Details extends Component {

  constructor(props){
    super(props)
    this.showRevision = this.showRevision.bind(this)
  }

  componentDidMount(){
    this.tocoRefresh()
  }

  showRevision(id){
    this.props.history.push("/components/"+ this.props.article.article.article_id + "/revision/" + id )
  }

  // ページ内リンク
  tocoRefresh(){

    let promise = new Promise((resolve, reject)=>{
      tocbot.init({
        tocSelector: '.toc',
        contentSelector: '.preview',
        headingSelector: 'h1, h2, h3, h4',
        hasInnerContainers: true
      });
      resolve()
    })
    promise.then((msg)=>{
      if ($('.toc-list li a').length !== 0) {
        const speed = 500
        $('.toc-list li a').each(function (e) {
          $(this).on('click', function (e) {
            e.preventDefault()
            const href = $(this).attr("href")
            const target = $(href == "#" || href == "" ? 'html' : href)
            const position = target.offset().top - 110
            $("html, body").animate({scrollTop: position}, speed, "swing")
          })
        })
      }
    })
  }

  render(){

    let content = '',
        title,
        revision
    
    if(typeof this.props.article.article === 'object'){
      if( this.props.article.article.content == null ){
        content = '本文なし'
      }else{
        content = this.props.article.article.content
      }
      if( this.props.article.article.title == null ) {
        title = '本文なし'
      }else{
        title = this.props.article.article.title
      }
    }

    if( typeof this.props.article === 'object' ){
      if( Array.isArray(this.props.article.revision)){
        revision = this.props.article.revision.map((data)=>{
          return(
            <li key={data.id} className="nav-item">
              <span  onClick={()=> this.showRevision(data.id)} className="">{data.revision_title}</span>
            </li>
          )
        })
      }
    }
    
    return(
      <div className="row">
        <div className={(()=>{
          if(this.props.article.article.anchorLink || this.props.article.revision.length > 0 ){
            return ('col-12')
          }else{
            return ('col-12')
          }
        })()}>
          <h1>{title} {this.props.article.anchorLink}</h1>

          {(()=>{
            if( this.props.article.article.source_id != null ){
              return(
                <Alert color="warning mb-0">この記事はリビジョンです</Alert>
              )
            }
          })()}
          <div className="preview__wrapper">
            {(()=>{
              if(this.props.article.article.anchorLink || this.props.article.revision.length > 0 ){
                return(
                  <div className="toc_outer">
                    <h2>この記事の目次</h2>
                    <div className="toc" />
                  </div>
                )
              }
            })()}
            <Preview data={content} article_id={this.props.article.article.id} part="front" match={this.props.match} history={this.props.history} />
          </div>
        </div>

      </div>
    )
  }
}

export default withRouter(Details)
