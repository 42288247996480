import { combineReducers, applyMiddleware } from 'redux'
import article from './article'
import articles from './articles'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-115167510-24')
const gaTrackingMiddleware = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.pathname}${action.payload.search}`;
    trackPage(nextPage);
  }
  return next(action);
}

const trackPage = page => {
  ReactGA.pageview(page);
}

export default combineReducers(
  {article,articles},
  applyMiddleware(gaTrackingMiddleware)
)
