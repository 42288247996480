import { connect } from 'react-redux'

import RevisionList from './../../common/Admin/RevisionList'
import Actions from './../../actions/articleActions'

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        handleSetRevision(value) {
            dispatch(Actions.setRevision(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RevisionList)
