const Config = () => {
    if (process.env.REACT_APP_MODE === 'dev') {
        // development
        return {
            restful: {
                apiURL: process.env.REACT_APP_DEV_API_URL
            }
        }
    } else if (process.env.REACT_APP_MODE === 'prod') {
        // production
        return {
            restful: {
                apiURL: process.env.REACT_APP_PROD_API_URL
            }
        }
    } else if (process.env.REACT_APP_MODE === 'staging'){
        // local
        return {
            restful: {
                apiURL: process.env.REACT_APP_STAGING_API_URL
            }
        }
    } else if (process.env.REACT_APP_MODE === 'test'){
        // local
        return {
            restful: {
                apiURL: process.env.REACT_APP_TEST_API_URL
            }
        }
    } else {
        return {
            restful: {
                apiURL: process.env.REACT_APP_LOCAL_API_URL
            }
        }
    }
}
export const Conf = Config().restful


