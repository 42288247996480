import { connect } from 'react-redux'
import Main from './../layouts/Main'
import Actions from './../actions/articleActions'

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
      handleSetArticle(value) {
          dispatch(Actions.setArticle(value))
      },
      handleSetArticles(value) {
          dispatch(Actions.setArticles(value))
      },
      handleSetRevision(value){
        dispatch(Actions.setRevision(value))
      },
      handleSetChildren(value){
        dispatch(Actions.setChildren(value))
      },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
