import React, { Component, Children } from 'react'
import { Link, withRouter } from 'react-router-dom'
import * as conf from '../config/config'
import axios from 'axios'
import $ from 'jquery'
import tocbot from 'tocbot'
import {Helmet} from "react-helmet"
import CategoryTree from '../common/CategoryTree'

class Main extends Component {

  constructor(props){
    super(props)
    
    this.state = {
      articles: {},
      anchorLink: '',
      title: 'ecforce MALL GUIDE テーマガイド',
      description: 'ecforce　はLP作成から受注・顧客データ管理分析など、ECに必要な機能を揃えたオールインワンECプラットフォーム。顧客体験を重視した様々な機能が充実、ビジネスの立ち上げからグロースのフェーズに合わせたオンラインショップが簡単に開設可能。',
    }
    this.getArticle = this.getArticle.bind(this)
    this.setArticle = this.setArticle.bind(this)
    this.getArticleChildren = this.getArticleChildren.bind(this)
  }

  componentDidMount(){
    this.getArticles()
    this.getTree()
    this.adjustmentWindow()
    this.getArticle()
  }

  // ウィンドウ整形
  adjustmentWindow(){
    const ua = navigator.userAgent
    if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
      
    }else{
      $('.sidenavi').css({
        'height': $(window).height() - $('header').height() + 'px'
      })
      $(window).resize(function(){
        $('.sidenavi').css({
          'height': $(window).height() - $('header').height() + 'px'
        })
      })
    }
  }

  // ページ内リンク
  tocoRefresh(){
    tocbot.init({
      tocSelector: '.toc',
      contentSelector: '.preview',
      headingSelector: 'h2, h3, h4',
    });
  }

  // サイドツリー
  getTree(){
    const t = this
    axios
      .get(conf.Conf.apiURL+"/v1/articles/tree")
      .then((result)=>{
        this.setState({
          articles: result.data
        })
      })
      .catch((error)=>{
        console.log(error)
      })
      .then(()=>{
        $('.nav-link').click(function(){
          $(this).parent('.nav-link-outer').next('.inner').slideDown()
          $(this).next('.icon-collapse').addClass('active')
          $('.nav-link').removeClass('correct')
          $(this).addClass('correct')
        })
        $('.icon-collapse').click(function(){
          if($(this).hasClass('active')){
            $(this).parent('.nav-link-outer').next('.inner').slideUp()
            $(this).removeClass('active')
          }else{
            $(this).parent('.nav-link-outer').next('.inner').slideDown()
            $(this).addClass('active')
          }
        })        
      })
  }
  

  // ツリー整形
  tree = (value)=>{
    return value.map((data,i)=>{
      let child = ''
      if( typeof data.child !== 'undefined'){
        if(Array.isArray(data.child[0])){
          child = data.child[0].map((child)=>{

            if(Array.isArray(child.child[0])){
              return (
                <li key={child.id} className="nav-item nav-parent">
                  <div className="nav-link-outer">
                    <span
                      // onClick={()=>this.showArticle(child.id)}
                      className="nav-link">
                      {child.title}
                    </span>
                    {(()=>{
                      if(child){
                        return (
                          <span className="icon-collapse"></span>
                        )
                      }
                    })()}
                  </div>
                  {this.tree_loop(child, i)}
                </li>
              )
            }else{
              return (
                <li key={child.id} className="nav-item">
                  <span
                    onClick={()=>this.showArticle(child.id)}
                    className="nav-link">{child.title}</span>
                </li>
              )
            }

          })
        }

        return <li key={data.id} className="nav-item">
                 <div className="nav-link-outer">
                   <span
                    //  onClick={()=>this.showArticle(data.id)}
                     className="nav-link js-collapse">
                     {data.title}
                   </span>
                   {(()=>{
                     if(child){
                       return (
                         <span className="icon-collapse"></span>
                       )
                     } 
                   })()}
                 </div>
                 {(()=>{
                   if(child){
                     return (<ul className="nav-sub inner">
                     {child}
                   </ul>)
                   }
                 })()}

               </li>
        i++
      }
    })

  }

  // ツリー整形 処理
  tree_loop = ( value ) => {
    return(
      <ul className="nav-sub inner">
        {(() => {
          if(Array.isArray(value.child[0])){
            return value.child[0].map((child2)=>{
              return <li key={child2.id} className="nav-item"><span
                      key={child2.id}
                      onClick={()=>this.showArticle(child2.id)}
                      className={(()=>{
                        if(child2.status === "draft" ){
                          return 'draft nav-link'
                        }else if(child2.status === "review"){
                          return 'review nav-link'
                        }else{
                          return 'nav-link'
                        }
                      })()}
                 >{child2.title}</span>
                       {this.tree_loop(child2)}
                     </li>

            })
          }else{
            return false
          }
        }
         )()}
      </ul>
    )
  }

  // 記事取得
  getArticle(){
    const article_id = window.location.pathname.replace(/\/components\//,"")
    const setArticle = this.setArticle

    const t = this
    let res

    if( !isNaN(article_id)) {
      axios
        .get(conf.Conf.apiURL + "/v1/articles/" + article_id)
        .then((result) => {
          if (result.data.content.indexOf('#') !== -1) {
            this.setState({
              anchorLink: true,
              title: result.data.title
            })
          } else {
            this.setState({
              anchorLink: false,
              title: result.data.title
            })
          }
          res = result
          this.getRevision(result.data.id)
        })
        .catch((error) => {
          console.log(error)
          t.setState({
            error: true
          })
        })
        .then(() => {
          if (res !== undefined) {
            this.props.handleSetArticle(setArticle(res.data))
          }
        })
        .then(() => {
          this.tocoRefresh()
        })
        .then(() => {
          if ($('.toc-list li a').length !== 0) {
            const speed = 500
            $('.toc-list li a').each(function (e) {
              $(this).on('click', function (e) {
                e.preventDefault()
                const href = $(this).attr("href")
                const target = $(href == "#" || href == "" ? 'html' : href)
                const position = target.offset().top - 110
                $("html, body").animate({scrollTop: position}, speed, "swing")
              })
            })
          }
        })
    }
  }

  getRevision(id){
    axios
      .get(conf.Conf.apiURL+"/v1/articles/"+id+"/revision")
      .then((result)=>{
        this.props.handleSetRevision(result.data.articles)
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  // ツリー 取得
  getArticles(){
    axios
      .get(conf.Conf.apiURL+"/v1/articles")
      .then((result)=>{
        this.setState ({
          articles: result.data
        })
      })
      .catch((error)=>{
        console.log(error)
        this.setState({
          error:true
        })
      })
  }

  // 記事取得redux
  setArticle(result) {
    let status = '',
        color = ''
    switch(result.status){
    case "draft" :
      status = '下書き'
      color = 'secondary'
      break
    case "review":
      status = 'レビュー待ち'
      color = 'warning'
      break
    case "display":
      status = '公開'
      color = 'success'
      break
    default:
      break
    }
    const article = {
      title: result.title,
      content: result.content,
      status: status,
      color: color,
      article_id: result.id,
      update: result.updated_at,
      date: new Date(),
      condition: false,
      anchorLink: this.state.anchorLink,
      source_id: result.source_id
    }
    return article
  }

  // 記事取得
  showArticle(id){
    this.props.history.push('/components/'+id)
    this.getArticle()
    this.getArticleChildren(id)
    window.scrollTo(0,0)
  }

  getArticleChildren(id){
    
    axios
      .get(conf.Conf.apiURL+"/v1/articles/"+id+"/article_tree")
      .then((result)=>{
        this.props.handleSetChildren(result.data)
      })
      .catch((error)=>{
        console.log(error)
      })
        
  }

  render(){
      let list = '',
        li = '',
        tree

    if( Array.isArray(this.state.articles)){
      tree = this.tree(this.state.articles,0)
    }

    const additionalProps = {
    }
      
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child)=>{
        return React.cloneElement(child,additionalProps)
      })
    
    return(
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.title} | ecforce テーマガイド</title>
          <meta name="description" content={this.state.description} />
          <meta property="og:locale" content="ja_JP" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this.state.title + ' | ecforce テーマガイド'}  />
          <meta property="og:description" content={this.state.description} />
          <meta property="og:url" content="https://theme.super-studio.jp/" />
          <meta property="og:site_name" content="ecforce MALL GUIDE テーマガイド" />
          <meta property="og:image" content="/img/ogp_default_image.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content={this.state.description} />
          <meta name="twitter:title" content={this.state.title + ' | ecforce テーマガイド'} />
        </Helmet>
        <header className="navbar container-fluid">
          <h1 className="brand">
            <Link to="/">
              <img src="/img/themeguide_logo.svg" alt="SUPER STUDIO ecforce MALL GUIDE" />
            </Link>
          </h1>
          {/* <ul className="nav">
            <li className="nav-item">
              <Link to="/">TOP</Link>
            </li>
          </ul> */}
        </header>
        <div className="row_outer">
          <div className="sidenavi">
            <div className="inner">
              <ul className="nav flex-column nav-type3">
                {tree}
              </ul>
            </div>
          </div>
          <div className="main_content">
            {childrenWithProps}
          </div>
        </div>
        <footer className="footer">
          <div className="footer__inner">
            <img src="/img/footer-logo.png" alt="SUPER STUDIO ecforce MALL GUIDE" />
            <p className="footer__copyright">© 2018 SuperStudio.inc</p>
          </div>
        </footer>
      </div>
    )
  }
}

export default withRouter(Main)
