import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, Navbar, NavbarBrand, NavbarToggler,Collapse, Nav, NavItem, UncontrolledDropdown,
         DropdownToggle, DropdownMenu, DropdownItem,NavLink,} from 'reactstrap'
import ArticleDelete from './ArticleDelete'
import RivisionList from '../containers/Admin/RevisionList'
import CreateRevision from './../containers/Admin/CreateRevision'

class AdminHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.toggle = this.toggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    window.localStorage.removeItem('access_token')
    this.setState({
      redirect: true
    })
    this.props.history.push('/admin/login')
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  
  render(){
    return(
      <Navbar color="none" light expand="md" className="pr-2">
        <NavbarBrand>{this.props.title}</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink>
                <CreateRevision match={this.props.match} history={this.props.history} />
              </NavLink>
            </NavItem>
            <NavItem>
              <Link to={"/admin/"+this.props.id+"/edit"} className="nav-link">                    
                <Button size="md" color="primary" ><i className="fas fa-edit"></i>&nbsp;edit
                </Button>
              </Link>
            </NavItem>
            <NavItem>
              <NavLink>
                <RivisionList match={this.props.match} history={this.props.history} size="md" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink><ArticleDelete articleId={this.props.id} /></NavLink>
            </NavItem>
            
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <Button color="link" size="sm">メニュー</Button>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link to="/admin">管理トップ</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/">トップページ</Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.handleSubmit} >
                  ログアウト
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default withRouter(AdminHeader)


