import { combineReducers } from 'redux'

const initialState = {
  article_title: '',
  article_content: '',
  article_status: '',
  article_id: '',
  anchorLink: false,
  article: {
    anchorLink: false,
  }
}

const article = (state = initialState, action) => {
  switch (action.type) {
  case 'SETARTICLE': {
    return action.value
  }
  default: 
    return state
  }
}

const revision = (state = initialState, action) => {
  switch (action.type) {
  case "SETREVISION": {
    return action.value
  }
  default: 
    return state
  }
}

const theme = combineReducers({
  article,
  revision
})

export default theme

