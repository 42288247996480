import { combineReducers } from 'redux'

const initialState = {
}

const articles = (state = initialState, action) => {
  switch (action.type) {
  case 'SETARTICLES': {
    return action.value
  }
  default: 
    return state
  }
}

const article_children = (state = initialState, action) => {
  switch (action.type) {
  case 'SETCHILDREN': {
    return action.value
  }
  default: 
    return state
  }
}

const theme = combineReducers({
  articles,
  article_children
})
  

export default theme

