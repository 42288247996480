import React, { Component, Children } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { ListGroup, ListGroupItem, Col } from 'reactstrap'
import * as conf from './../config/config'
import {Helmet} from "react-helmet"

class Admin extends Component {

  constructor(props){
    super(props)
    this.state = {
      articles: [],
      title: 'ecforce MALL GUIDE テーマガイド',
      description: 'リリースわずか10ヶ月で導入社数100社突破！現ビッグデータを様々な角度から分析し、より最適な販売手法、CRM施策、分析機能などをデータドリブン開発により、サービスに反映し続けています。',
      revision: []
    }

    this.getArticle   = this.getArticle.bind(this)
    this.setArticle   = this.setArticle.bind(this)
    this.getTree      = this.getTree.bind(this)
    this.showArticle  = this.showArticle.bind(this)
    this.getRevision  = this.getRevision.bind(this)
  }

  componentDidMount(){

      this.getArticle()
      this.getTree()
    
  }

  // サイドツリー
  getTree(){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+"/v1/admin/articles/tree")
      .then((result)=>{
          this.props.handleSetArticles(result.data)
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  // サイドツリー
  getArticles(){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    axios
      .get(conf.Conf.apiURL+"/v1/admin/articles_asc")
      .then((result)=>{
        this.setState ({
          articles: result.data
        })
      })
      .catch((error)=>{
        console.log(error)
        this.setState({
          error:true
        })
      })
  }

  // 記事取得
  getArticle(){

    const article_id = window.location.pathname.replace(/\/admin\//,"")
    const setArticle = this.setArticle    
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    if( article_id !== '/admin'){
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+article_id)
        .then((result)=>{
          this.props.handleSetArticle(setArticle(result.data))
          this.setState({
            title: (result.data.title)? result.data.title : this.state.title
          })
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        })
    }
  }

  // 記事取得
  showArticle(id){
    let promise = new Promise((resolve, reject)=>{
      this.props.history.push('/admin/'+id)
      resolve()
    })
    promise.then((msg)=>{
      this.getRevision()
    }).then((msg)=>{
      this.getArticle()
    })
  }

  // 記事取得 redux
  setArticle(result) {
    let status = '',
        color = ''
    switch(result.status){
    case "draft" :
      status = 'draft'
      color = 'secondary'
      break
    case "review":
      status = 'review'
      color = 'warning'
      break
    case "display":
      status = 'draft'
      color = 'success'
      break
    case "revision":
      status = "revision"
      color  = "info"
    default:
      break
    }
    const article = {
      title: result.title,
      content: result.content,
      status: status,
      color: color,
      article_id: result.id,
      update: result.updated_at,
      date: new Date(),
      condition: false,
      revision_title: result.revision_title,
      source_id: result.source_id
    }
    return article
  }

  getRevision(){
    const article_id = window.location.pathname.replace(/\/admin\//,"")
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    
    if( article_id !== '/admin'){
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+article_id+"/revisions")
        .then((result)=>{
          this.props.handleSetRevision(result.data.articles)
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  }

  // ツリー整形
  tree = (value,i)=>{

    if( Array.isArray(value)){
      return value.map((data,i)=>{
        let child = ''

        if(Array.isArray(data.child[0])){
          child = data.child[0].map((child)=>{
            
            if(Array.isArray(child.child[0])){
              return (
                <li key={child.id}>
                  <span
                    onClick={()=>this.showArticle(child.id)}
                    className={(()=>{
                      if(child.status === "draft" ){
                        return 'draft'
                      }else if(child.status === "review"){
                        return 'review'
                      }
                    })()}
                  >
                    {child.title}
                  </span>
                  {this.tree_loop(child)}
                </li>
              )
            }else{
              return (
                <li key={child.id}>
                  <span
                    onClick={()=>this.showArticle(child.id)}
                    className={(()=>{
                      if(child.status === "draft" ){
                        return 'draft'
                      }else if(child.status === "review"){
                        return 'review'
                      }
                    })()}
                  >{child.title}</span>
                </li>
              )
            }

          })
        }
        return <li key={data.id}>
                 <span
                   onClick={()=>this.showArticle(data.id)}
                   className={(()=>{
                     if(data.status === "draft" ){
                       return 'draft'
                     }else if(data.status === "review"){
                       return 'review'
                     }
                   })()}
                 >{data.title}</span>
                 <ul className="level-1">
                   {child}
                 </ul>
               </li>
        i++
      })
    }
  }

  // ツリー整形処理
  tree_loop = ( value ) => {
    return(
      <ul className="level-2">
        {(() => {
          if(Array.isArray(value.child[0])){
            return value.child[0].map((child2)=>{
              return <li key={child2.id}><span
                                      key={child2.id}
                                      onClick={()=>this.showArticle(child2.id)}
                                      className={(()=>{
                                        if(child2.status === "draft" ){
                                          return 'draft'
                                        }else if(child2.status === "review"){
                                          return 'review'
                                        }
                                      })()}
                                         >{child2.title}</span>
                       {this.tree_loop(child2)}
                     </li>

            })
          }else{
            return false
          }
        }
         )()}
      </ul>
    )
  }                                                                        

  
  render(){

    let t  = this,
        tree

    if(typeof this.props.articles === 'object'){
      if( Array.isArray(this.props.articles.articles)){
        tree = this.tree(this.props.articles.articles, 0)
      }
    }
    
    const children = Children.map(this.props.children, (child) => {
      switch (typeof child) {
      case 'string':
        return child
      case 'object':
        return React.cloneElement(child, {getArticles: t.getArticles})            
      default:
        return null
      }
    })


    /**  redux使うとちょいズレする
     <Helmet>
     <meta charSet="utf-8" />
     <title>{this.state.title} | ecforce テーマガイド</title>
     <meta name="description" content={this.state.description} />
     <meta property="og:locale" content="ja_JP" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={this.state.title + ' | ecforce テーマガイド'}  />
     <meta property="og:description" content={this.state.description} />
     <meta property="og:url" content="https://theme.super-studio.jp/" />
     <meta property="og:site_name" content="ecforce(ECフォース) MALL GUIDE テーマガイド" />
     <meta property="og:image" content="/img/ogp_default_image.png" />
     <meta name="twitter:card" content="summary_large_image" />
     <meta name="twitter:description" content={this.state.description} />
     <meta name="twitter:title" content={this.state.title + ' | ecforce テーマガイド'} />
     </Helmet>
     **/

    return(
      <div style={{"height":"100%"}}>
        <div style={{"height":"100%"}}>
          <Col color="black" className="text-center side_menu pt-1">
            <ListGroup flush>
              <ListGroupItem>
                <Link to="/admin/create"><i className="fas fa-plus"></i></Link>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col className="pt-3 contents">
            <div className="contents_inner">
              <ul className="article_list">
                {tree}
              </ul>
            </div>
          </Col>
          <Col className="main content">
            {children}
          </Col>
        </div>
      </div>
    )
  }
}

export default withRouter(Admin)

