import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import combineReducers from './reducers/index'
import Routes from './config/routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-sortable-tree/style.css'
import './index.css'
import * as serviceWorker from './serviceWorker'

const store = createStore(
    combineReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

ReactDOM.render(
        <Provider store={store}>
          <Router>
            <Routes />
          </Router>
        </Provider>,
    document.getElementById('root')
)
serviceWorker.unregister()





