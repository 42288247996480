import React, { Component } from 'react'
import { Alert, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col } from 'reactstrap';
import * as conf from '../../config/config'
import axios from 'axios'
import $ from 'jquery'

class MergeRevision extends Component {
  
  constructor(props) {
    super(props)

    const { params } = this.props.match
    const id = parseInt(params.id, 10)
    this.state = {
      modal: false,
      prev: [],
      mode: false,
      visible: false,
      isOpen: false,
      article_id: id
    }
    this.toggle       = this.toggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){
  }

  handleSubmit(e){
    e.preventDefault()
    const t = this
    
    axios.defaults.headers['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+"/v1/admin/articles/"+this.state.article_id+"/merge")
      .then((result)=>{
        t.props.history.push("/admin/"+result.data.source_id)
        window.location.reload(false)
      })
      .catch((error)=>{
        console.log(error)
        this.setState({
          error:true
        })
      })
    
    
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  
  render() {

    let revisions = '',
        revision = false
    if( typeof this.props.article === 'object'){
      if( Array.isArray(this.props.article.revision)){
        revisions =  this.revision(this.props.article.revision)
      }
    }
    
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
    return(
      <div>
        <Button size="sm" color="info" className="" onClick={this.toggle}>
          merge
        </Button>
        
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " w-50"}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>リビジョンをマージ</ModalHeader>
          <ModalBody className="fs-14">
            <p className="fs-14">この作業は取り消せません。</p>
            <Button color="success" size="lg" className="fs-14 w-100" onClick={this.handleSubmit}>実行</Button>
          </ModalBody>
        </Modal>
      </div>
    )
  }

}

export default MergeRevision
