const Actions = {
  setArticle(value){
    return {
      type: 'SETARTICLE',
      value,
    }
  },
  setArticles(value){
    return {
      type: 'SETARTICLES',
      value
    }
  },
  setRevision(value){
    return {
      type: 'SETREVISION',
      value,
    }
  },
  setChildren(value){
    return {
      type: 'SETCHILDREN',
      value,
    }
  },
}

export default Actions
