import { connect } from 'react-redux'
import Admin from './../layouts/Admin'
import Actions from './../actions/articleActions'

const mapStateToProps = (state) => {
  return {
    article: state.article,
    articles: state.articles,
    revision: state.revision,
    childrens: state.childrens,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleSetArticle(value) {
      dispatch(Actions.setArticle(value))
    },
    handleSetArticles(value) {
      dispatch(Actions.setArticles(value))
    },
    handleSetRevision(value) {
      dispatch(Actions.setRevision(value))
    }
  }
}
/**
const mapDispatchToProps = (dispatch) =>({
  handleSetArticle:  value => dispatch( Actions.setArticle(value)),
  handleSetArticles: value => dispatch( Actions.setArticles(value))  
})**/

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
