import { connect } from 'react-redux'

import ShowArticleButton from './../../common/Admin/ShowArticleButton'
import Actions from './../../actions/articleActions'

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
      handleSetArticle(value) {
        dispatch(Actions.setArticle(value))
      },
      handleSetRevision(value) {
        dispatch(Actions.setRevision(value))
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowArticleButton)
