import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import * as conf from '../../config/config'
import $ from 'jquery';
import ImageGallery from './../../common/ImageGallery'
import CreateRevision from './../../containers/Admin/CreateRevision'
import {  Alert,  Button,  FormGroup,  Label,  Input,  Collapse,  Navbar,  NavbarToggler,
          NavbarBrand,  Nav,  NavItem,  NavLink,  Col,  Row,  Dropdown, DropdownToggle,
          DropdownMenu, DropdownItem } from 'reactstrap';

import Editor from './../../common/Editor'
import Preview from './../../common/Preview'
import RevisionList from '../../containers/Admin/RevisionList'
import ReactDiffViewer from 'react-diff-viewer'
import MergeRevision from '../../common/Admin/MergeRevision'

const required = (value) => {
  if (!value.toString().trim().length) {
    return (<span className="error required">入力必須項目です</span>)
  }
};

class AdminDiffArticle extends Component {
  
  constructor(props) {
    super(props);

    let status = ''
    if(typeof this.props.article.article === 'object'){
      if( this.props.article.article.status ){
        status = this.props.article.article.status
      }else{
        status = "draft"
      }
    }

    const { params }  = this.props.match,
          article_id  = parseInt( params.id, 10 )
    
    this.state = {
      isOpen: false,
      value: '',
      title: '',
      content: '',
      status: (status!='')?status:"draft",
      visible: false,
      error: false,
      message: '',
      articles: [],
      parent_id: '',
      slug: '',
      slug_check: true,
      dropdownOpen: false,
      setDropdownOpen: false,
      source_id: '',
      revision_title: '',
      revisions: [],
      visible_source: false,
      revision: {},
      source: {},
      article_id: article_id
    }
    this.toggle        = this.toggle.bind(this)
    this.toggleMenu    = this.toggleMenu.bind(this)
    this._edit         = this._edit.bind(this)
    this.handleChange  = this.handleChange.bind(this)
    this.handleSubmit  = this.handleSubmit.bind(this)
    this.back           = this.back.bind(this)
    this.backParent     = this.backParent.bind(this)
    this.onDismiss      = this.onDismiss.bind(this)
    this.onDismissError = this.onDismissError.bind(this)
  }
  
  onDismiss(){
    this.setState({ visible: false})
  }
  onDismissError(){
    this.setState({ error: false})
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  _edit(text){
    this.setState({ value: text })
  }
  
  toggleMenu(){
    this.setState({
      dropdownOpen : !this.state.dropdownOpen
    })
  }

  componentDidMount(){
    $('.preview_area, #editor, .editor_outer').css({
      'height': $(window).height() - $('.navbar').height() - $('.parent_select').height() + 'px'
    });
    this.getRevision()
    this.getRevisions()
  }

  getArticle(id){
    if(!isNaN(id)){
      axios.defaults.headers['Authorization'] = localStorage.getItem('access_token')
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+id)
        .then((result)=>{
          this.setState({
            source: result.data
          })
          this.getRevisions(result.data.id)
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });
    }else{
      if( typeof this.props.article.article == 'object') {
        this.setState({
          parent_id: this.props.article.article.article_id
        })
      }
    }
  }

  getRevision(){

    const {params} = this.props.match
    const id = parseInt(params.id, 10)
    
    if(!isNaN(id)){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+id)
        .then((result)=>{
          this.setState({
            revision: result.data
          })
          this.getArticle(result.data.source_id)
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  }

  getRevisions(id){
    if(!isNaN(id)){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+id+"/revisions/")
        .then((result)=>{
          this.setState({
            revisions: result.data.articles
          })
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  }
  
  handleChange(event){
    const t = this,
          e = event
    this.setState({ [event.target.name]: event.target.value });
    let promise = new Promise((resolve, reject)=>{
      this.props.history.push("/admin/"+event.target.value+"/diff")
      resolve()
    })
    promise.then((msg)=>{
      this.getRevision()
    })
  }  

  handleSubmit(e){
    e.preventDefault();
    
    let parent_id = ''
    const { params } = this.props.match
    const id = parseInt(params.id, 10)
    if(!isNaN(id)){
      parent_id = this.state.parent_id
    }else{
      if(typeof this.props.article.article == 'object'){
        parent_id = (this.props.article.article.article_id)?this.props.article.article.article_id:0
      }else{
        parent_id = 0
      }
    }
    const postData = {
      article: {
        title: this.state.title,
        content: this.state.value,
        status: (this.state.status)?this.state.status:"draft",
        parent_id:parent_id,
        revision_title: this.state.revision_title,
        slug: this.state.slug
      }
    }
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');

    if( isNaN(id) ){

      axios
        .post(conf.Conf.apiURL+"/v1/admin/articles",postData)
        .then((result)=>{
          this.setState({
            message:'success!',
            visible:true
          })
          this.props.history.push('/admin/'+result.data.id)
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });
    }else{

      axios
        .patch(conf.Conf.apiURL+"/v1/admin/articles/"+id,postData)
        .then((result)=>{
          this.setState({
            visible:true
          })
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });

    }
  }
  
  back = () => {
    this.props.history.goBack("/admin/"+this.state.article_id);
  }

  backParent = () => {

  }

  categoryChild = (parent) => {
    if( typeof parent === 'object') {
      if(Array.isArray(this.state.articles)){
        return this.state.articles.map((data)=>{
          if( data.parent_id === parent.id ){
            return <option key={data.id} value={data.id}> - {data.title}</option>
          }
        })
      }
    }
  }

  render(){

    let parents = ''
    let child = this.categoryChild()

    if(Array.isArray(this.state.revisions)){
      parents = this.state.revisions.map((data)=>{
        if( this.state.revision.id != data.id ){
          return (
            <option key={data.id} value={data.id}>{data.revision_title}</option>
          )
        }
      })
    }
    
    return(
      <div className="create">
        <Navbar color="light" light expand="md" className="d-flex">
          <NavbarBrand className="title_form">
            <FormGroup row>
              <Label htmlFor="parent" sm={3}>リビジョン</Label>
              <Col sm={9}>
                <select className="form-control" id="parent" value={this.state.parent_id} name="parent_id" size="md" onChange={this.handleChange}>
                  <option value={this.state.revision.id}>{this.state.revision.revision_title}</option>
                  {parents}
                </select>
              </Col>
            </FormGroup>

          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            
            <Row className="w-100">
              <Nav sm={7} className="d-flex ml-auto">
                <NavItem>
                  <NavLink>
                    <MergeRevision match={this.props.match} history={this.props.history} />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Button color="secondary" size="sm" onClick={this.back}>
                      戻る
                    </Button>
                  </NavLink>
                </NavItem>
                {(()=>{
                  if( this.state.source_id == null ){
                    return(
                      <NavItem>
                        <NavLink>
                          <RevisionList match={this.props.match} history={this.props.history} />
                        </NavLink>
                      </NavItem>
                    )
                  }
                })()}
                
                <NavItem className="d-flex align-items-center">
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleMenu}>
                    <DropdownToggle className="" caret size="sm" color="light">
                      <i className="fal fa-ellipsis-h fs-16 toggleMenu"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem className="fs-12" onClick={this.backParent}>親記事に戻る</DropdownItem>  
                      <DropdownItem className="fs-12">リビジョンをマージする</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              </Nav>
            </Row>
          </Collapse>
        </Navbar>
        <Row >
          <Col className="parent_select" sm="6">
            <p className="text-center fs-12 mb-0 ">{this.state.revision.revision_title}</p>
          </Col>
          <Col className="parent_select" sm="6">
            <p className="text-center fs-12 mb-0 ">現行版とのDiff</p>
          </Col>
        </Row>
        <Alert isOpen={this.state.visible} color="success" fade={true}  toggle={this.onDismiss}>success!</Alert>
        <Alert isOpen={this.state.error} color="danger" fade={true}  toggle={this.onDismissError}>something wrong!</Alert>
        <Row style={{"minHeight": "100%", "margin": 0}} className="editor_outer">
          <Col>
            <ReactDiffViewer
              oldValue={this.state.source.content}
              newValue={this.state.revision.content}
              splitView={true}
              showDiffOnly={false}
            />
          </Col>
        </Row>
      </div>
    )
  }

}

export default withRouter(AdminDiffArticle)
