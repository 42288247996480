import React, { Component } from 'react'
import {  Route, withRouter, Redirect } from 'react-router-dom'
import * as conf from '../../config/config.js'
import axios from 'axios'

class Auth extends Component {
    constructor(props){
        super(props)
        this.state = {
            isAuthenticated: false
        }
        this.userWillTransfer = this.userWillTransfer.bind(this)
    }

    componentWillMount() {
        this.userWillTransfer(this.props)
    }

    userWillTransfer(props) {
        let p = this.props
        if (typeof localStorage.getItem('access_token') === undefined) {
            this.props.hisotry.push('/admin/login')
            this.setState({ isAuthenticated: false })
        } else {
            this.setState({ isAuthenticated: true })
        }

        const postData = {
            access_token: localStorage.getItem('access_token')
        }
        axios
            .post(conf.Conf.apiURL+"/v1/check",postData)
            .then((result)=>{
                
            })
            .catch((error)=>{
                p.history.push('/admin/login')
            })
    }

    render() {
        return (
            this.state.isAuthenticated? (
                <Route children={this.props.children} />
            ) : (
                <Redirect to={'/admin/login'} />
            )
        )
    }
}

export default withRouter(Auth)
