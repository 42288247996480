import React, { Component, Children } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { ListGroup, ListGroupItem, Col, Button } from 'reactstrap'
import * as conf from './../../config/config'
import {Helmet} from "react-helmet"

class ShowArticleButton extends Component {

  constructor(props){
    super(props)

    const { params } = this.props.match,
          article_id = parseInt( params.id, 10 )

    this.state = {
      article_id: article_id
    }
    this.getArticle   = this.getArticle.bind(this)
    this.setArticle   = this.setArticle.bind(this)
    this.showArticle  = this.showArticle.bind(this)
    this.getRevision  = this.getRevision.bind(this)
  }

  componentDidMount(){
  }

  // 記事取得
  getArticle(){
    const setArticle = this.setArticle    
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    
    if( !isNaN(this.state.article_id) ){
      axios
        .get(conf.Conf.apiURL+"/v1/admin/articles/"+ this.props.article_id)
        .then((result)=>{
          this.props.handleSetArticle(setArticle(result.data))
          this.setState({
            title: (result.data.title)? result.data.title : this.state.title
          })
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        })
    }
  }

  // 記事取得
  showArticle(id){
    let promise = new Promise((resolve, reject)=>{
      this.props.history.push('/admin/'+id)
      resolve()
    })
    promise.then((msg)=>{
      this.getRevision()
    }).then((msg)=>{
      this.getArticle()
    }).then((msg)=>{
      this.props.toggle()
    })
  }

  // 記事取得 redux
  setArticle(result) {
    let status = '',
        color = ''
    switch(result.status){
    case "draft" :
      status = 'draft'
      color = 'secondary'
      break
    case "review":
      status = 'review'
      color = 'warning'
      break
    case "display":
      status = 'draft'
      color = 'success'
      break
    case "revision":
      status = "revision"
      color  = "info"
    default:
      break
    }
    const article = {
      title: result.title,
      content: result.content,
      status: status,
      color: color,
      article_id: result.id,
      update: result.updated_at,
      source_id: result.source_id,
      revision_title: result.revision_title,
      date: new Date(),
      condition: false,
    }
    return article
  }


  getRevision(){

    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    
    axios
      .get(conf.Conf.apiURL+"/v1/admin/articles/"+this.props.article_id+"/revisions")
      .then((result)=>{
        this.props.handleSetRevision(result.data.articles)
      })
      .catch((error)=>{
        console.log(error)
      })
  }
  
  render(){
    
    return(
      <Button size="sm" color="success" onClick={(()=>this.showArticle(this.props.article_id))} >show</Button>
    )
  }
}

export default withRouter(ShowArticleButton)

