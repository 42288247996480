import { connect } from 'react-redux'

import CreateRevision from './../../common/Admin/CreateRevision'
import Actions from './../../actions/articleActions'

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        handleSetArticle(value) {
            dispatch(Actions.setArticle(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRevision)
