import { connect } from 'react-redux'

import Create from './../../components/Admin/AdminDiffArticle'
import Actions from './../../actions/articleActions'

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleSetArticle(value) {
      dispatch(Actions.setArticle(value))
    },
    handleSetRevision(value){
      dispatch(Actions.setRevision(value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Create)
