import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Main from '../containers/Main'
import Index from '../components/Index'
import Detail from '../containers/Details'
import Diff from '../containers/Diff'

import Login from '../components/Admin/Login'
// import Signup from '../components/Admin/Signup'
import Auth from '../components/Admin/Auth'
import AdminLayout from '../containers/Admin'
import AdminIndex from '../containers/Admin/AdminIndex'
import Create from '../containers/Admin/Create'
import AdminDiffArticle from '../containers/Admin/AdminDiffArticle'
import withTracker from './withTracker'

class Routes extends Component {

  /**
   *
   * @returns {*}
   */

  render(){
    return(
      <Router>
        <Switch>
          <Route exact path="/admin/create" component={withTracker(Create)} />
          <Route exact path="/admin/:id/edit" component={withTracker(Create)} />
          <Route exact path="/admin/:id/diff" component={withTracker(AdminDiffArticle)} />
          <Route exact path="/admin/login" component={withTracker(Login)} />
          <Auth path="/admin">
            <AdminLayout path="/admin">
              <Route exact path="/admin" component={withTracker(AdminIndex)} />
              <Route exact path="/admin/:id" component={withTracker(AdminIndex)}  />
            </AdminLayout>
          </Auth>
          <Main>
            <Route exact path="/" component={withTracker(Index)} />
            <Route exact path="/components/:id" component={withTracker(Detail)} />
            <Route exact path="/components/:id/revision/:revision_id" component={withTracker(Diff)} />
          </Main>
        </Switch>
      </Router>
    )
  }
}
export default Routes
