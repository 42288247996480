import { connect } from 'react-redux'

import Preview from './../common/Preview'
import Actions from './../actions/articleActions'

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => ({
  handleSetArticle:  value => dispatch( Actions.setArticle(value)),
  handleSetArticles: value => dispatch( Actions.setArticles(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
