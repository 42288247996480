import React, { Component } from 'react'
import {Helmet} from "react-helmet"

class Index extends Component {

  render(){
    return(
        <div className="row">
          <div className="col-12">
            <div className="preview top">
              <h2>
                <img src="/img/faq_logo.svg" alt="ecforce FAQサイト" />
              </h2>
              <p>ecforce のご利用方法・機能等の検索、マニュアルはこちらのガイドをご覧ください。</p>
              <p className="mb-6">
                <a href="https://support.ec-force.com/hc/ja" target="_blank">
                  詳しくはこちら
                </a>
                <i className="fas fa-external-link-alt ml-4 text-muted"></i>
              </p>
              
              <h2>
                <img src="/img/ecforce_logo.svg" alt="ecforceブランドサイト" />
              </h2>
              <p>ecforceはスタートアップから大企業まであらゆるビジネスのEC化を支援するECプラットフォームです。コト、モノにかかわる全ての人々の顧客体験の最大化を実現していきます。</p>
              <p className="mb-6">
                <a href="https://ec-force.com/" target="_blank">
                  詳しくはこちら
                </a>
                <i className="fas fa-external-link-alt ml-4 text-muted"></i>
              </p>
              <h2>
                <img src="/img/super_studio_logo.svg" alt="SUPERSTUDIO公式" />
              </h2>
              <p>SUPER STUDIOは、コト、モノにかかわる全ての人々の顧客体験を最大化することをミッションに掲げる、D2Cのトータルソリューション企業です。</p>
              <p className="mb-6">
                <a href="https://super-studio.jp/" target="_blank">
                  詳しくはこちら
                </a>
                <i className="fas fa-external-link-alt ml-4 text-muted"></i>
              </p>
            </div>
          </div>
        </div>
    )
  }
}

export default Index
