import React, { Component } from 'react'
import axios from 'axios'
import tocbot from 'tocbot'
import * as conf from './../config/config'
import $ from 'jquery'

class CategoryTree extends Component {

  constructor(props){
    super(props)
    let article_id = window.location.pathname.replace(/\/admin\//,"")
    if (article_id.search("components") > 0 ){
      const { params } = this.props.match
      article_id = params.id
    }

    this.state = {
      article_id: article_id,
    }

    this.getArticle  = this.getArticle.bind(this)
    this.showArticle = this.showArticle.bind(this)
    this.setArticle  = this.setArticle.bind(this)
  }

  componentDidMount(){
    this.getArticles()
  }

  getRevision(id){
    axios
      .get(conf.Conf.apiURL+"/v1/articles/"+id+"/revision")
      .then((result)=>{
        this.props.handleSetRevision(result.data.articles)
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  // ページ内リンク
  tocoRefresh(){
    let promise = new Promise((resolve, reject)=>{
      setTimeout(function(){
        tocbot.init({
          tocSelector: '.toc',
          contentSelector: '.preview',
          headingSelector: 'h1, h2, h3, h4',
        });
      },100)
      resolve()
    })

    let t = this
    promise.then((msg)=>{
      setTimeout(function(){
        t.anchorLink()
      },500)
    })
  }

  anchorLink(){
    const speed = 500
    $('.toc-list li a').each(function (e) {
      $(this).on('click', function (e) {
        e.preventDefault()
        const href = $(this).attr("href")
        const target = $(href == "#" || href == "" ? 'html' : href)
        const position = target.offset().top - 110
        $("html, body").animate({scrollTop: position}, speed, "swing")
      })
    })
  }

  showArticle(id){
    let promise = new Promise((resolve, reject)=>{
      this.props.history.push('/components/'+id)
      resolve()
    })
    promise.then((msg)=>{
      this.getArticle()
      this.getRevision(id)
    })
    promise.then((msg)=>{
      this.tocoRefresh()
    })
  }

  // 記事取得redux
  setArticle(result) {
    
    let article = {
      title: result.title,
      content: result.content,
      article_id: result.id,
      update: result.updated_at,
      date: new Date(),
      condition: false,
      anchorLink: true,
      source_id: result.source_id
    }
    return article
  }

  getArticles(){
    axios
      .get(conf.Conf.apiURL+"/v1/articles/"+this.state.article_id+"/article_tree")
      .then((result)=>{
        this.setState({
          articles: result.data,
        })
        this.props.handleSetChildren(result.data)
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  // 記事取得
  getArticle(){
    const setArticle = this.setArticle
    const { params } = this.props.match
    const article_id = params.id
    axios
      .get(conf.Conf.apiURL+"/v1/articles/"+article_id)
      .then((result)=>{
        this.props.handleSetArticle(setArticle(result.data))
      })
      .catch((error)=>{
        console.log(error)
        this.setState({
          error:true
        })
      })
  }

  // ツリー整形
  tree = (value)=>{
    return value.map((data,i)=>{
      let child = ''
      if( typeof data.child !== 'undefined'){
        if(Array.isArray(data.child[0])){
          child = data.child[0].map((child)=>{

            if(Array.isArray(child.child[0])){
              return (
                <li key={child.id} className="nav-item nav-parent">
                  <div className="nav-link-outer">
                    <span
                      onClick={()=>this.showArticle(child.id)}
                      className="nav-link">
                      {child.title}
                    </span>
                    {(()=>{
                      if(child){
                        return (
                          <span className="icon-collapse"></span>
                        )
                      }
                    })()}
                  </div>
                  {this.tree_loop(child, i)}
                </li>
              )
            }else{
              return (
                <li key={child.id} className="nav-item">
                  <span
                    onClick={()=>this.showArticle(child.id)}
                    className="nav-link">{child.title}</span>
                </li>
              )
            }

          })
        }

        return <li key={data.id} className="nav-item">
                 <div className="nav-link-outer">
                   <span
                     onClick={()=>this.showArticle(data.id)}
                     className="nav-link js-collapse">
                     {data.title}
                   </span>
                   {(()=>{
                     if(child){
                       return (
                         <span className="icon-collapse"></span>
                       )
                     } 
                   })()}
                 </div>
                 {(()=>{
                   if(child){
                     return (<ul className="nav-sub inner">
     {child}
   </ul>)
                   }
                 })()}

               </li>
        i++
      }
    })

  }

  // ツリー整形 処理
  tree_loop = ( value ) => {
    return(
      <ul className="nav-sub inner">
        {(() => {
          if(Array.isArray(value.child[0])){
            return value.child[0].map((child2)=>{
              return <li key={child2.id} className="nav-item"><span
              key={child2.id}
              onClick={()=>this.showArticle(child2.id)}
              className={(()=>{
                if(child2.status === 0 ){
                  return 'draft nav-link'
                }else if(child2.status === 1){
                  return 'review nav-link'
                }else{
                  return 'nav-link'
                }
              })()}
                                                              >{child2.title}</span>
                       {this.tree_loop(child2)}
                     </li>
            })
          }else{
            return false
          }
        }
         )()}
      </ul>
    )
  }

  render(){

    let tree = ''

    if( Array.isArray(this.props.articles.childrens)){
      tree = this.tree(this.props.articles.childrens,0)
    }
    
    return(
      <ul className="nav">
        {tree}
      </ul>
    )
  }
}

export default CategoryTree
