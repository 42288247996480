import React, { Component } from 'react'
import { Badge, Alert, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col } from 'reactstrap';
import classNames from 'classnames'
import * as conf from '../../config/config'
import axios from 'axios'
import $ from 'jquery'
import { Animated } from 'react-animated-css'
import Moment from 'react-moment';
import ShowArticleButton from '../../containers/Admin/ShowArticleButton'
import CreateRevision from '../../containers/Admin/CreateRevision'

class RevisionList extends Component {
  
  constructor(props) {
    super(props)

    const { params }  = this.props.match,
          article_id  = parseInt( params.id, 10 )

    this.state = {
      modal: false,
      prev: [],
      mode: false,
      visible: false,
      isOpen: false,
      articles: [],
      article_id: article_id
    };
    this.toggle          = this.toggle.bind(this);
    this.getRevisionList = this.getRevisionList.bind(this)
    this.editArticle     = this.editArticle.bind(this)
    this.loadEdit        = this.loadEdit.bind(this)
  }

  componentDidMount(){
    this.getRevisionList()
  }

  getRevisionList(){
    let parent_id = ''
    const { params } = this.props.match
    const id = parseInt(params.id, 10)
    
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token')
    axios
      .get(conf.Conf.apiURL+"/v1/admin/articles/"+id+"/revisions")
      .then((result)=>{
        this.props.handleSetRevision(result.data.articles)
      })
      .catch((error)=>{
        console.log(error)
      })
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));

  }

  loadEdit(){

    if(!isNaN(this.state.article_id)){
      axios.defaults.headers['Authorization'] = localStorage.getItem('access_token')
      axios
        .get(conf.Conf.apiURL+"/v1/articles/"+this.state.article_id)
        .then((result)=>{
          this.setState({
            id: result.data.id,
            title:result.data.title,
            value:(result.data.content)?result.data.content:'',
            status:result.data.status,
            parent_id: result.data.parent_id,
            slug: result.data.slug,
            source_id: result.data.source_id,
            revision_title: result.data.revision_title,
            visible_source: (result.data.source_id != null)?true:false
          })
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        });
    }else{
      if( typeof this.props.article.article == 'object') {
        this.setState({
          parent_id: this.props.article.article.article_id
        })
      }
    }
  }
  
  showArticle(id){
    this.props.history.push('/admin/'+id)
    this.toggle()
  }

  editArticle(id){
    this.props.history.push('/admin/'+id+'/edit')
    this.toggle()
    //window.location.reload(false)
  }

  diffArticle(id){
    this.props.history.push('/admin/'+id+'/diff')
  }

  revision = (value)=>{
    return value.map((data)=>{
      return(
	      <tr key={data.id}>
          <td>
            <Moment parse="YYYY-MM-DD HH:mm" format="YYYY/MM/DD">{data.created_at}</Moment>
          </td>
          <td>
            <span onClick={()=>this.editArticle(data.id)}>
              {data.revision_title}
            </span>
          </td>
          <td>
            {(()=>{
              switch(data.status){
              case 'draft':
                return (<Badge color="secondary">下書き</Badge>)
                break
              case 'review':
                return <Badge color="info" size="md">レビュー待ち</Badge>
                break
              case 'display':
                return <Badge color="success">公開中</Badge>
                break
              default:
                break
              }
            })()}
          </td>
          <td>
            <ShowArticleButton history={this.props.history} article_id={data.id} toggle={()=>this.toggle()} />
            <Button size="sm" color="primary" onClick={(()=>this.editArticle(data.id))} className="ml-2">
              edit
            </Button>
            <Button size="sm" color="info" onClick={(()=>this.diffArticle(data.id))} className="ml-2">diff</Button>
          </td>
        </tr>
      )
    })
  }

  render() {

    let revisions = '',
        revision = false
    if( typeof this.props.article === 'object'){
      if( Array.isArray(this.props.article.revision)){
        revisions =  this.revision(this.props.article.revision)
      }
    }
    
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
    return(
      <div>        
        <Button size={(this.props.size)?this.props.size:"sm"} color="secondary" className="" onClick={this.toggle}>
          rivision
        </Button>
        
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " w-50"}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>リビジョン一覧</ModalHeader>
          <ModalBody className="fs-14">            
            <div className="input-group mb-3">
              <table>
                <thead>
                  <tr>
                    <th>作成日</th>
                    <th>リビジョン名</th>
                    <th>ステータス</th>
                    <th colSpan="2"></th>
                  </tr>
                </thead>
                <tbody>
                  {revisions}
                </tbody>
                
              </table>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }

}

export default RevisionList
