import React, { Component } from 'react'
import { Alert, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Col } from 'reactstrap';
import classNames from 'classnames'
import * as conf from '../../config/config'
import axios from 'axios'
import $ from 'jquery'
import { Animated } from 'react-animated-css'

class CreateRevision extends Component {
  
  constructor(props) {
    super(props)

    const { params }  = this.props.match,
          article_id  = parseInt( params.id, 10 )
    
    this.state = {
      modal: false,
      prev: [],
      mode: false,
      del: false,
      visible: false,
      isOpen: false,
      title: '',
      revision_title: '',
      article_id: article_id
    }
    
    this.toggle       = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.toggle       = this.toggle.bind(this)
    this.onDismiss    = this.onDismiss.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){

  }

  handleSubmit(e){
    e.preventDefault()

    let parent_id = ''
    const { params } = this.props.match
    const id = parseInt(params.id, 10)

    if(!isNaN(id)){
      parent_id = this.state.parent_id
    }else{
      if(typeof this.props.article.article == 'object'){
        parent_id = (this.props.article.article.article_id)?this.props.article.article.article_id:0
      }else{
        parent_id = 0
      }
    }
    const postData = {
      article: {
        id: id,
        revision_title: this.state.revision_title
      }
    }
    const article_id = window.location.pathname.replace(/\/admin\//,"")
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
    let props = this.props
    
    if( article_id !== '/admin'){
      axios
        .post(conf.Conf.apiURL+"/v1/admin/articles/create_revision", postData)
        .then((result)=>{
          if( result.status == 200 ){
            let promise = new Promise((resolve, reject)=>{
              this.props.history.push("/admin/"+result.data.article.id+"/edit")
              resolve()
            })
            promise.then((msg)=>{
              if( typeof props.loadEdit === 'function'){
                props.loadEdit()                
              }
            }).then((msg)=>{
              if( typeof props.loadRevision === 'function'){
                props.loadRevision()
              }
              window.location.reload(false)
            })
            console.log(result)
          }
        })
        .catch((error)=>{
          console.log(error)
          this.setState({
            error:true
          })
        })
    }
  }
  
  onDismiss(){
    this.setState({ visible: false})
  }
  
  onDismissError(){
    this.setState({ error: false})
  }

  handleChange(e){
    this.setState({ [e.target.name]: e.target.value });
  }
  
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    
    const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
    return(
      <div>
        <Button size="sm" color="light" className="" onClick={this.toggle}>
          <i className="far fa-copy"></i>
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " w-50"}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>リビジョンの作成</ModalHeader>
          <ModalBody className="fs-14">
            <div className="input-group mb-3">
              <FormGroup row className="w-100">
                <Label htmlFor="exampleEmail" sm={3}>タイトル</Label>
                <Col sm={9}>
                  <Input className="py-4 px-4 fs-14" type="text" name="revision_title" value={this.state.revision_title}  placeholder="〇〇年〇〇月〇〇日アップデート" onChange={this.handleChange} required />
                </Col>
              </FormGroup>
              <FormGroup row className="w-100">
                <Col className="text-right pt-4" sm={12}>
                  <Button color="success" size="lg" onClick={this.handleSubmit}>
                    保存
                  </Button>
                </Col>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }

}

export default CreateRevision
